import RecommendationsComponent from "./recommendation_component";

export default function Recommendations() {
    return (
        <div>
            <RecommendationsComponent
                name="Mr. Nikhil Jose"
                designation="Senior Consultant, KPMG | IIMA"
                recommendation={
                    "I managed Sam during his internship at KPMG, where he contributed to some of the practical most critical ongoing projects. He worked on creating Machine Learning models, performing EDA, and algorithms to automate repetitive tasks.\n\n Sam has displayed great technical acumen along with implementation practicality for the projects assigned to him. \n\nHis adaptability coupled with his excellent work ethic ensures that he will be a valuable addition to any team he works with in the future."
                }
                profilePhoto={require("../../images/nikhil_jose_kpmg.jpg")}
                source="Linkedin Recommendations"
                link="https://linkedin.openinapp.co/p0yl3"
            />

            <RecommendationsComponent
                name="Mr. Rudresh Agaskar"
                designation="Dpty. Direc. Placements | NMIMS"
                recommendation={
                    "@ All My Contacts - Hardik Pandya was picked up by Mumbai Indians in 2015 for his base price of INR 10 Lacs. We all know his IPL price tag now!! MI did excellent value investing in him then... \n\nSam Varghese will be a steal when he is up for grabs considering the value he will be adding to the company.... \n\nKeep your eyes on him... "
                }
                profilePhoto={require("../../images/rudresh_agaskar.jpg")}
                source="Linkedin Comments"
                link="https://www.linkedin.com/feed/update/urn:li:ugcPost:7133037810906714112?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7133037810906714112%2C7133308184336773120%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287133308184336773120%2Curn%3Ali%3AugcPost%3A7133037810906714112%29"
            />
            
            <RecommendationsComponent
                name="Dr. Suman Chakraborty"
                designation="Prof. & Assoc. Dean | NMIMS"
                recommendation={
                    "Sam led the first National Winning Team from NMIMS at the Smart India Hackathon. I'm confident in his skills, academic excellence, and hands-on experience which will make him a valuable asset for any organization!"
                }
                profilePhoto={require("../../images/Suman-Chakraborty.jpg")}
                source="Reference"
                link="#"
            />

            <RecommendationsComponent
                name="Mr. Thomas Hansen"
                designation="CEO @ AINIRO | Cyprus"
                recommendation={
                    "Sam worked for us as an intern at the end of 2021 beginning of 2022, and he was amazingly motivated and showed some incredible 'go get' attitude.\n\n At the time I had little time to tutor and coach Sam unfortunately, yet Sam ran with the tasks I gave him, and quite frankly baffled me with his motivation. I think this was his first Angular project, but within a week of development, he had managed to acquire a deep understanding of the framework and a lot of its internal details.\n\n Sam is a positive and super motivated software developer from my point of view, and I would recommend him to anyone lucky enough to be associated with him in any ways."
                }
                profilePhoto={require("../../images/thomas_hansen.jpg")}
                source="Linkedin Recommendations"
                link="https://linkedin.openinapp.co/p0yl3"
            />
        </div>
    );
}